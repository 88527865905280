import React, { useState, createContext, useContext } from 'react';

import { languageOptions, dictionaryList } from '../languages';

// create the language context with default selected language
export const LanguageContext = createContext({
  language: localStorage.getItem('lang')?languageOptions[localStorage.getItem('lang')==='English'?0:1]:languageOptions[0],
  dictionary: localStorage.getItem('lang')?dictionaryList[languageOptions[localStorage.getItem('lang')==='English'?0:1].id]:dictionaryList[languageOptions[0].id]
});

// it provides the language context to app
export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage);
      setDictionary(dictionaryList[selectedLanguage.id]);
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {props.children}
    </LanguageContext.Provider>
  );
};

// get text according to id & current language
export function Text(props) {
  const languageContext = useContext(LanguageContext);

  return (
    <div style={{textAlign:languageContext.dictionary['dir']==='rtl'?'right':'left'}}>{languageContext.dictionary[props.tid]}</div>
  )
};
