import React, { useContext } from 'react';
import Routes from "./Routes";
import { LanguageProvider } from './components/Language';
import LanguageSelector from './components/LanguageSelector';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import { LanguageContext, Text } from './components/Language';
import AutoDirectionProvider from 'react-with-direction/dist/AutoDirectionProvider';

export default function App() {
    React.useEffect(()=>{
        if(localStorage.getItem('lang')){

        }else{
            localStorage.setItem('lang','English')
        }
    },[])
    const languageContext = useContext(LanguageContext);
    
    const childProps = {
        isAuthenticated: false,
        userHasAuthenticated: false,
        setUserHasAuthenticated: false
    };
    return (

        <LanguageProvider>
            <AutoDirectionProvider text={localStorage.getItem('lang')}>
                {/* <DirectionProvider direction={languageContext.dictionary['dir'] === 'ltr' ? DIRECTIONS.RTL : DIRECTIONS.LTR}> */}
                <Routes childProps={childProps} />
                {/* </DirectionProvider> */}
            </AutoDirectionProvider>

        </LanguageProvider>

    );
};