import React, { useContext } from 'react';

import { languageOptions } from '../languages';

import { LanguageContext, Text } from '../components/Language';

import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';



export default function LanguageSelector() {
    const languageContext = useContext(LanguageContext);
    console.log(languageContext)
    const handleLanguageChange = (event) => {
        const selectedLanguage = languageOptions.find(item => item.id === event);
        // set selected language by calling context method
        console.log(selectedLanguage)
        languageContext.setLanguage(selectedLanguage);
        localStorage.setItem('lang',selectedLanguage.text)
        window.location.reload();

    };
    const menu = (
        <Menu>
            {languageOptions.map(item => (
                <Menu.Item  value={languageContext.language.id}>
                        <a value={item.id} onClick={()=>handleLanguageChange(item.id)}>
                            <img src={item.url} style={{ width: '25px' }} />
                        </a>
                </Menu.Item>
            ))}

        </Menu>
    );
    return (
        <Dropdown overlay={menu}>
            <a className="ant-dropdown-link hidden" onClick={e => e.preventDefault()}>
                <img src={languageContext.language.url} style={{ width: '25px' }} />
                <DownOutlined />
            </a>
        </Dropdown>
    );
};
