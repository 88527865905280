import fa from './fa';
import en from './en';
import iran from '../assets/images/vector/flag/iran.svg'
import AUS from '../assets/images/vector/flag/australia.svg'
export const dictionaryList = {
  fa,
  en
};

export const languageOptions = [
  { id: 'en', text: 'English',url:AUS,dir:'ltr'},
  { id: 'fa', text: 'فارسی',url:iran,dir:'rtl' },


];
