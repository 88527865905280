import React from "react";

const fa = {
    dir:'rtl',
    // landTitle: 'WE MAKE IT OUR BUSINESS \n TO HELP YOUR BUSINESS',
    // welcomeDescription: 'This is a demo app for multi-language website with React Context API',
    // clickMe: 'Click Me',
    // aboutMe: 'For more info about the author',
    // buttonClicked: 'You clicked to button!'
    landTitle: 'ما کسب و کار را می سازیم برای کمک به تجارت شما',
    landTitle1:'زمانی فرا می رسد که هر مشاغل در حال گسترش نیاز به این کار دارد\n' +
        '  کمک به یک شرکت مشاوره برای به حداقل رساندن دردهای در حال رشد',
    landTitle2:'ما ارگانیک می سازیم\n' +
        '  روابط با همه مشتریان ما برای اطمینان از راه حل های سفارشی\n' +
        '  بدون قربانی کردن شخصیت مشاغلشان ملاقات کردند.',
    Services:"خدمات",
    sTitle:'ما تیم کوچکی از داستان نویسان ، متفکران و حل کننده های مشکل هستیم.',
    Ideation:'ایده پردازی',
    ideationTitle:'ما به شرکت شما کمک می کنیم تا احساس واقعی خود را از طریق صدای صدا منعکس کند',
    explore:'ادامه مطالب',
    Strategy:'استراتژی',
    strategyTitle:'ما به شرکت شما کمک می کنیم تا احساس واقعی خود را از طریق صدای صدا منعکس کند',
    scale:'توسعه تجاری',
    scaleTitle:'ما به شرکت شما کمک می کنیم تا احساس واقعی خود را از طریق صدای صدا منعکس کند',
    biz:'طرح تجاری',
    bizTitle:'ما به شرکت شما کمک می کنیم تا احساس واقعی خود را از طریق صدای صدا منعکس کند',
    special:'خدمات ویژه',
    specialTitle:'ما تیم آژانس دیجیتال هستیم که برای حل مشکل شما کمک می کند.',
    creative:'بهترین راه حل خلاق',
    creativeTitle:'We help your company to reflect its true self through tone of voice',
    BrandingSupport:'پشتیبانی از برند',
    BusinessConsultancy:'مشاوره تجاری',
    StartupBusiness:'راه اندازی کسب و کار',
    GoodServiceGuarantee:'ضمانت خدمات خوب',
    Testimonial:'نظرات مشتریان',
    testimonialTitle:'نقل قول های ویژه\n' +
        '  از مشتریان در مورد ما',
    succeed: 'موفقیت',


    //header
    home:'صفحه اصلی',
    aboutUs:'درباره ما',
    services:"خدمات",
    bp:'شرکای کاری',
    whatsNew:'تازه ها',
    contact:'تماس با ما',
    faq:'سوالات متداول',

    language:'زبان',

    welcomeDescription: 'This is a demo app for multi-language website with React Context API',
    clickMe: 'Click Me',
    aboutMe: 'For more info about the author',
    buttonClicked: 'You clicked to button!',

    // footer

    footerDes:'روه مشاورین فرین مستقر در استرالیا، متشکل از گروهی خلاق، حرفه ای و پیش اندیش هستند که در زمینه مشاوره بیزینس و استراتژی به استارت آپ ها و شرکت های کوچک و متوسط فعالیت دارند.\n' +
        'روش های علمی و مبتکرانه ما به بسیاری از شرکت ها در راستای رشد پیوسته کسب و کار آنها کمک کرده است.\n' +
        'خدمات ما شامل آموزش (Coaching) ، برنامه ریزی استراتژی، هدف گذاری، مدیریت گردش مالی، بودجه بندی، مدیریت پروژه، بهبود پروسه کاری و بازار سنجی میباشد.\n' +
        'تیم ما متشکل است از افراد متخصص با سالها تجربه و دانش در حوزه های مختلف و مجهز به مهارت های کلیدی و بروز ترین ابزارها در راستای بهترین راهکارهای جهت حل مسائل پیچیده بیزینس.',

    sendEmail:'ارسال',
    copyRight:'تمامی حقوق برای شرکت فرین محفوظ می باشد.',
    SubscribeUs:'ارتباط با ما',
    smallDes:'طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه',

    // about us
    aboutUsTitle: 'درباره ما',
    aboutUsDescription:'کمک به کسب و کار شما در ایجاد موفقیت',
    aboutUsMoreDes:'مسیر خود را برای گسترش توسعه دهید',
    aboutCompany:'گروه مشاورین فرین مستقر در استرالیا، متشکل از گروهی خلاق، حرفه ای و پیش اندیش هستند که در زمینه مشاوره بیزینس و استراتژی به استارت آپ ها و شرکت های کوچک و متوسط فعالیت دارند.\n' +
        'روش های علمی و مبتکرانه ما به بسیاری از شرکت ها در راستای رشد پیوسته کسب و کار آنها کمک کرده است.\n' +
        'خدمات ما شامل آموزش (Coaching) ، برنامه ریزی استراتژی، هدف گذاری، مدیریت گردش مالی، بودجه بندی، مدیریت پروژه، بهبود پروسه کاری و بازار سنجی میباشد.\n' +
        'تیم ما متشکل است از افراد متخصص با سالها تجربه و دانش در حوزه های مختلف و مجهز به مهارت های کلیدی و بروز ترین ابزارها در راستای بهترین راهکارهای جهت حل مسائل پیچیده بیزینس.',
    aboutUsCoFounderName1:'فرهاد فتاحی',
    CoFounderDes1:'طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده',
    CoFounderJob:'موسس',
    aboutUsCoFounderName2:'فرشید لعل کائی',
    CoFounderDes2:'طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده',
    benefitsTitle:'گروه مشاورین فرین چگونه میتواند به شما کمک کند؟',
    benefits1:'مشاوری امین که میتواند در مورد فرصت های توسعه کسب و کارتان و رفع موانع پیش رویتان با آنها صحبت کنید.',
    benefits2:'مشاورینی که در حوزه های مختلف تخصص دارند و راهبرد های استراتژیک خود را به شکل کاربردی به شما ارائه میدهند.',
    benefits3:'ارائه راهکارهای ساده و موثر در صورت مواجهه با مشکلات پیچیده در هر مرحله از کسب و کارتان.',
    benefits4:'ایجاد زمان برای شما از طریق افزایش راندمان جهت تمرکز بیشتر بر روی کسب و کارتان.',
    benefits5:'ارائه خدمات در جهت هموار سازی، ثبات و سوددهی کسب و کار شما.',
    benefits6:'مشاورینی که به ارائه خدمات با کیفیت درعین درک زمان شما باور دارد.',
    benefits7:'ما مشتاق به حفظ روابط دراز مدت با مشتریانمان هستیم و بر این باوریم که تداوم آن به رشد اعتماد طرفین کمک خواهد کرد.',
    benefits8:'',


    // Services

    servicesTitle:'خدمات',
    servicesIdeation:'ایده پردازی',
    servicesStrategy:'استراتژی',
    servicesScaleUP:'توسعه تجاری',
    servicesBizPlan:'طرح تجاری',
    ExploreMore:'بیشتر',
    servicesIdeationItem1:'خود راه اندازی (bootstrapping)',
    servicesIdeationItem2:'استارت آپ لین (Lean)',
    servicesIdeationItem3:'تفکر طراحی (Design Thinking)',
    servicesIdeationItem4:'تحقیق و بررسی از دیدگاه مشتری',
    servicesBizPlanItem1:'بررسی بازار',
    servicesBizPlanItem2:'وام بانکی',
    servicesBizPlanItem3:'برآورد گردش مالی',
    servicesBizPlanItem4:'گزاره ارزش (Value Proposition)',
    servicesBizPlanItem5:'پیش بینی سود و زیان',
    servicesScaleUPItem1:'استراتژی ورود به بازار',
    servicesScaleUPItem2:'جذب سرمایه عمومی',
    servicesScaleUPItem3:'توسعه کسب و کار',
    servicesScaleUPItem4:'پیش بینی فروش',
    servicesScaleUPItem5:'راهنمایی کلی راه اندازی کسب و کار',
    servicesScaleUPItem6:'هدف گذاری',
    servicesStrategyItem1:'برنامه ریزی استراتژیک',
    servicesStrategyItem2:'مشاوره فناوری',
    servicesStrategyItem3:'استراتژی بازاریابی و پیاده سازی آن',
    servicesStrategyItem4:'بازاریابی در شبکه های اجتماعی',


    // Business Partners

    BusinessPartnersTitle:'شرکای کاری',
    BusinessPartnersDes:'طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد.',
    FinancialServicesTitle:'خدمات مالی',
    FinancialServicesDes:'Star Financial Center یک کارگزاری مشهور است که با ایجاد درک در مورد تغییر نیازهای مالی در مراحل مختلف زندگی ، به ایجاد رابطه ای پایدار با مشتریان و هیات وام دهندگان خود اختصاص داده است. مرکز مالی استار دارای MFAA (انجمن وام و امور مالی استرالیا) و همچنین عضو AFCA (مرجع شکایات مالی استرالیا) است. با دسترسی به بیش از 100 محصول مختلف مالی در 30 وام دهنده مختلف ، تیم ما می تواند گزینه ها و فرصتی خاص برای نیازهای فردی شما فراهم کند. روابط نزدیک کار ما با هیئت وام دهندگان ما ، همیشه حضور و رابطه خود را با مشتریان خود حفظ می کنیم.',
    AccountingTitle:'حسابداری',
    AccountingDes:'Star Financial Center یک کارگزاری مشهور است که با ایجاد درک در مورد تغییر نیازهای مالی در مراحل مختلف زندگی ، به ایجاد رابطه ای پایدار با مشتریان و هیات وام دهندگان خود اختصاص داده است. مرکز مالی استار دارای MFAA (انجمن وام و امور مالی استرالیا) و همچنین عضو AFCA (مرجع شکایات مالی استرالیا) است. با دسترسی به بیش از 100 محصول مختلف مالی در 30 وام دهنده مختلف ، تیم ما می تواند گزینه ها و فرصتی خاص برای نیازهای فردی شما فراهم کند. روابط نزدیک کار ما با هیئت وام دهندگان ما ، همیشه حضور و رابطه خود را با مشتریان خود حفظ می کنیم.',
    ImmigrationTitle:'مهاجرت',
    ImmigrationDes:'Star Financial Center یک کارگزاری مشهور است که با ایجاد درک در مورد تغییر نیازهای مالی در مراحل مختلف زندگی ، به ایجاد رابطه ای پایدار با مشتریان و هیات وام دهندگان خود اختصاص داده است. مرکز مالی استار دارای MFAA (انجمن وام و امور مالی استرالیا) و همچنین عضو AFCA (مرجع شکایات مالی استرالیا) است. با دسترسی به بیش از 100 محصول مختلف مالی در 30 وام دهنده مختلف ، تیم ما می تواند گزینه ها و فرصتی خاص برای نیازهای فردی شما فراهم کند. روابط نزدیک کار ما با هیئت وام دهندگان ما ، همیشه حضور و رابطه خود را با مشتریان خود حفظ می کنیم.',
    seeWebsiteLink:'نمایش سایت',



    // What’s New

    WhatIsNewTitle:'تازه ها',
    WhatIsNewSectionTitle1:'اخبار',
    WhatIsNewSectionTitle2:'آموزش',
    NewsTitle:'عنوان خبر',
    NewsDes:'طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه',
    NewsAuthor:'توسط zizi',
    NewsDate:'۲ روز پیش',


    // Contact us

    ContactUsTitle:'تماس با ما',
    ContactUsDes:'اگر سوالی کلی دارید یا درخواستی در مورد خدمات ما دارید ، لطفاً از فرم تماس زیر استفاده کنید.',
    ContactUsSend:'ارسال',


    // FAQ

    FAQLine1:'سوالات متداول',
    FAQLine2:'',
    FAQDes:'طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد.',
    questionBoxTitle:'سوالات عمومی',
    questionBoxDes:'طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد.'
};

  export default fa;
