const en = {
    dir:'ltr',
    landTitle: 'WE MAKE IT OUR BUSINESS \n TO HELP YOUR BUSINESS',
    landTitle1:'There comes a time when every expanding business requires the \n help of a consulting firm to minimize growing pains.',
    landTitle2:'We build organic \n relationships with all of our clients to ensure custom solutions are \n met without sacrificing the personality of their businesses.',
    Services:"Services",
    sTitle:'We’re a small team of storytellers, \n thinkers and problem solvers.',
    Ideation:'Ideation',
    ideationTitle:'We help your company to reflect its true self through tone of voice',
    explore:'Explore More',
    Strategy:'Strategy',
    strategyTitle:'We help your company to reflect its true self through tone of voice',
    scale:'Scale UP',
    scaleTitle:'We help your company to reflect its true self through tone of voice',
    biz:'Biz Plan',
    bizTitle:'We help your company to reflect its true self through tone of voice',
    special:'Special Features',
    specialTitle:'We’re team of digital agency \n that helps to solves your  problem.',
    creative:'Best Creative Solution',
    creativeTitle:'We help your company to reflect its true self through tone of voice',
    BrandingSupport:'Branding Support',
    BusinessConsultancy:'Business Consultancy',
    StartupBusiness:'Startup Business',
    GoodServiceGuarantee:'Good Service Guarantee',
    Testimonial:'Testimonial',
    testimonialTitle:'What our customers say about us',
    succeed: 'SUCCEED',


    //header
    home:'Home',
    aboutUs:'About Us',
    services:"Services",
    bp:'Business Partners',
    whatsNew:'What’s new',
    contact:'Contact Us',
    faq:'FAQ',
    language:'language',


    welcomeDescription: 'This is a demo app for multi-language website with React Context API',
    clickMe: 'Click Me',
    aboutMe: 'For more info about the author',
    buttonClicked: 'You clicked to button!',


    // footer
    footerDes: 'Farin Consulting possesses with a team of experts assisting start-ups and small businesses to ' +
        'grow, maintain and sustain in Australia. We provide business consulting and strategy planning ' +
        'advice services to start-ups and small businesses to achieve success.',
    sendEmail:'send',
    copyRight:'Copyright© 2024 Farin Consulting Pty Ltd. All rights reserved.',
    SubscribeUs:'Subscribe us',
    smallDes:'Please submit your email address below to receive our newsletters!',
    // about us
    aboutUsTitle: 'About us',
    aboutUsDescription:' Helping Your Business Build Success',
    aboutUsMoreDes:' Develop Your Path to Greatness',
    aboutCompany:'' +
        '      Farin Consulting is an Australia based innovative, professional and forward-thinking business\n' +
        '                        consulting and strategy advice firm specialises to support business management systems and\n' +
        '                        services to start-ups and SMEs of all sizes.\n' +
        '                       ' +
        '                        Our practical and exclusive approach helped several businesses to increase their business\n' +
        '                        growth consistently. We provide business coaching, strategic planning, goal setting, business\n' +
        '                        strategy, cashflow management, budgeting assistance, project management, business\n' +
        '                        process improvement and market research services.\n' +
        '                       ' +
        '                        We have a team of highly skilled professionals having years of experience in multiple industries\n' +
        '                        and well equipped with latest skills and tools to find best solutions for complex\n' +
        '                        business-related problems.',
    aboutUsCoFounderName1:'Farhad Fattahi',
    CoFounderDes1:'Farhad\’s background is mechanical engineering with years of experience in construction, ' +
        'product development, project management, business development and start-up consultancy.' +
        ' Farhad is a Lean Six Sigma Black Belt and holds Master of Business Administration in Entrepreneurship' +
        ' (MBAe) from University of Technology Sydney.\n',
    CoFounderJob:'co-founder',
    aboutUsCoFounderName2:'Faro Laal',
    CoFounderDes2:'Faro\’s background is IT specialising in Cloud Computing and has been providing business' +
        ' development, product development and consultancy services to start-ups to grow their business.' +
        ' Faro holds Master of Business Administration in Entrepreneurship (MBAe) from University of ' +
        ' Technology Sydney.',
    benefitsTitle:'BENEFITS YOU GET BY HIRING FARIN CONSULTING',
    benefits1:'A consultant with whom you can talk about your business development opportunities and weak areasfor improvement.',
    benefits2:'Strategic expertise and sound knowledge of industry, offering realistic advice.',
    benefits3:'When in high demand and stucked in some critical projects, we give you the access to an additional business resource.',
    benefits4:'Give more time and focus to run your business.',
    benefits5:'Help to take you on a way of smooth, stable and profitable business.',
    benefits6:'We believe in professionalism and that encourages us to provide professional services to our clients.',
    benefits7:'We believe in providing quality work while taking care of the time as we know\n' +
        '                                the value of your time too, we believe in delighting our customers in terms of\n' +
        '                                work quality and value of time both.',
    benefits8:'We believe in maintaining the relation with our clients for long time so that it\n' +
        '                                increases our trusts or reliability both and gives us various referral works too.\n' +
        '                                It is a best way of maintaining quality business engagement.',


    // Services

    servicesTitle:'Services',
    servicesIdeation:'Ideation',
    servicesStrategy:'Strategy',
    servicesScaleUP:'Scale UP',
    servicesBizPlan:'Biz Plan',
    ExploreMore:'Explore More',
    servicesIdeationItem1:'Bootstrapping',
    servicesIdeationItem2:'Lean Startup',
    servicesIdeationItem3:'Design Thinking',
    servicesIdeationItem4:'Customer Research and Insight',
    servicesBizPlanItem1:'Market Research',
    servicesBizPlanItem2:'Bank Loan',
    servicesBizPlanItem3:'Cashflow Estimation',
    servicesBizPlanItem4:'Value Proposition',
    servicesBizPlanItem5:'Profit and Loss forecast',
    servicesScaleUPItem1:'Go-to-market Strategy',
    servicesScaleUPItem2:'Crowd Funding',
    servicesScaleUPItem3:'Business Development',
    servicesScaleUPItem4:'Sales Forecast',
    servicesScaleUPItem5:'General Launch Guidance',
    servicesScaleUPItem6:'Goal Setting',
    servicesStrategyItem1:'Strategic Planning',
    servicesStrategyItem2:'Technology Consulting',
    servicesStrategyItem3:'Marketing Strategy and Implementation',
    servicesStrategyItem4:'Social Media Marketing',


    // Business Partners

    BusinessPartnersTitle:'Business Partners',
    BusinessPartnersDes:'We are partnered with leading service providers in Financial Services and Accounting domain' +
        '                    to provide our customers with profesisonal services and peace of mind.',
    FinancialServicesTitle:'Financial Services',
    FinancialServicesDes:' Star Financial Center is a well-known brokerage company dedicated to\n' +
        '                    building a lasting relationship with it’s clients and panel of lenders by\n' +
        '                    developing an understanding of changing finance needs at different stages\n' +
        '                    of their life.\n' +
        '                    Star Financial Center holds MFAA (Mortgage and Finance Association of\n' +
        '                    Australia) and is also a member of AFCA (Australian Financial Complaints\n' +
        '                    Authority).\n' +
        '                    With access to over 100 different financial products across 30 different\n' +
        '                    lenders, our team can provide you with choices, and opportunity specific to\n' +
        '                    your individual needs. Our close working relationship with our panel of\n' +
        '                    lenders, always maintain our presence and relationship with our clients.',
    AccountingTitle:'Accounting',
    AccountingDes:          'E-tax Plus has provided the residents of Parramatta with reliable and high-quality ' +
        '                    service for over nine years, and we\'ve built a loyal customer base that returns to us' +
        '                    again and again for all of their personal and business tax needs. We have experience ' +
        '                    with sole traders, small businesses, large corporations, personal taxes and so much more.' +
        '                    Our friendly and professional staff are ready and willing to help you with all of ' +
        '                    your tax, bookkeeping, accounts management, corporate finance, or business ' +
        '                    start-up needs in an efficient manner. Our goal is to be the best tax accountants ' +
        '                    in Parramatta, and this is why we take so much pride in our connections with our ' +
        '                    customers and our work.',
    ImmigrationTitle:'',
    ImmigrationDes:'',
    seeWebsiteLink:'See website',



    // What’s New

    WhatIsNewTitle:'What’s New',
    WhatIsNewSectionTitle1:'News',
    WhatIsNewSectionTitle2:'Learning',
    NewsTitle:'Title',
    NewsDes:'Lorem ipsum dolor sit amet, consectetur adipiscing',
    NewsAuthor:'By zizi',
    NewsDate:'2 days age',


    // Contact us

    ContactUsTitle:'Contact us',
    ContactUsDes:'Please use the contact form below,\n' +
        '                                    if you have any questions or\n' +
        '                                    requests about our services.',
    ContactUsSend:'Send',


    // FAQ

    FAQLine1:'Frequently',
    FAQLine2:'Asked Questions',
    FAQDes:'Lorem ipsum dolor sit amet, consectetur\n' +
        '                                    adipiscing elit, sed do eiusmod tempor\n' +
        '                                    incididunt ut labore et dolore magna aliqua.',
    questionBoxTitle:'General Question',
    questionBoxDes:'We hope you will find the answer to your questions below, otherwise please contact us!'

  };

  export default en;
